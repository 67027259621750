<script setup lang="ts">
import { getMedia, getSrcSetForMedia } from '@shopware-pwa/helpers-next';
const props = defineProps<{
  customFields: any;
}>();

function isImage(url: string) {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url?.toLowerCase());
}

function isVideo(url: string) {
  return /\.(mov|mp4|m4a|m4v)$/.test(url?.toLowerCase());
}

const img = useImage()


</script>
<template>
  <div class="category-header is-sticky-hero block lg:grid grid-cols-2 items-center -lg:mt-40">
    <div class="left-col">
      <div class="hero-media lg:min-h-[700px]">
     
        <NuxtPicture format="webp"
        v-if="isImage(props.customFields?.custom_catalog_fields_hero_media_external)"
        :imgAttrs="{fetchPriority:'high'}"
        :src="props.customFields?.custom_catalog_fields_hero_media_external"
        :laszy-src="useRequestURL().protocol + '//' + useRequestURL().host + img((props.customFields?.custom_catalog_fields_hero_media_external), {quality: 100, blur: 30, width: 20, height: 20})"
        alt="Kategorie" 
        class="object-cover w-full lg:h-[clamp(700px,80vh,1200px)] flex [&>img]:object-cover [&>img]:w-full" 
        />
        <video v-lazy-load  v-if="isVideo(props.customFields?.custom_catalog_fields_hero_media_external)" muted autoplay loop playsinline class="object-cover w-full lg:h-[clamp(700px,80vh,1200px)]" :data-src="props.customFields?.custom_catalog_fields_hero_media_external" :data-poster="props.customFields?.custom_catalog_fields_hero_media_external_poster"></video>
      </div>
    </div>
    <div class="right-col">
      <div class="py-10 px-5 lg:p-16 lg:py-25 max-w-[770px] mx-auto">
        <div class="html-rendered" v-if="customFields?.custom_catalog_fields_hero_text" v-html="customFields?.custom_catalog_fields_hero_text"></div>
        <SharedAdjectives :adjectives="customFields?.custom_headless_adjektive" class="mt-5" />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.category-header {
  .html-rendered {
    h1,
    h2,
    h3 {
      margin-top: 0.25em;
      margin-bottom: 0.5em;
      font-weight: bold;
    }
  }
}
</style>
